import { lazy, Suspense } from "react";

import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/form-components.css";
import "react-toastify/dist/ReactToastify.css";
import "./assets/css/theme.scss";

import { Loader } from "./components/Loader/Loader";

import { BrowserRouter as Router } from "react-router-dom";

import { ToastContainer } from "react-toastify";
const RouterComp = lazy(() => import("./Router"));

function App() {
  return (
    <div className="App">
      <Loader>
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <Router>
          <Suspense fallback={<div>Loading Component</div>}>
            <RouterComp />
          </Suspense>
        </Router>
      </Loader>
    </div>
  );
}

export default App;
