import React, { createContext, useContext, useState } from "react";

import LoaderImage from '../../assets/icons/loader.svg';
import { Image } from 'react-bootstrap';

import './loader.css';

const LoadingContext = createContext({
    loading: false,
    setLoading: null,
});

export function Loader({ children }) {
    const [loading, setLoading] = useState(false);
    const value = { loading, setLoading };
    return (
        <LoadingContext.Provider value={value}>
            {
                loading ? <div className="xcd-loader bg-primary bg-opacity-25">
                    <div className="xcd-loader-icon ">
                        <Image src={LoaderImage} className="xcd-loader-image" />
                        <h6 className="fw-bold text-primary mt-2">Loading</h6>
                    </div>

                </div> : <> </>
            }

            {children}
        </LoadingContext.Provider>
    );
}

export function useLoader() {
    const context = useContext(LoadingContext);
    if (!context) {
        throw new Error("useLoader must be used within Loaderr");
    }
    return context;
}